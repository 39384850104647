@import 'node_modules/react-modal-video/scss/modal-video.scss';

.main_container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content_body {
    flex: 1 1 auto;
}

// stops modals from jumping to top of screen
body[class*='__bodyOpen'] {
    overflow: visible;
}

.ul_401 {
    list-style-position: inside;
}

.unauthorised {
    font-family: initial;
    margin-top: 15px;
    margin-left: 15px;

    h1 {
        font-family: inherit;
    }
}


.calc__container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    background-color: white;
    margin: 0 auto;
}

.warning__frame {
    background-color: rgb(240, 238, 234);
    margin-top: 10px;
    padding-left: 30px;
    padding-top: 15px;
    padding-right: 55px;
    padding-bottom: 20px;
}

.Header__Button {
    text-decoration: none;
    text-align: center;
    display: inline-flex;
    align-items: center;
    padding: 10px 0 10px 10px;
}

.disabled {
    .Header__Button {
        color: darkgray;
        cursor: none;

        &:focus,
        &:hover {
            text-decoration: none;
        }

        color: #99948a;
    }
}

#app {
    z-index: 1;
    position: relative;
}

.errorMsgText {
    color: #D0021B;
    font-size: 1em;
    font-weight: normal;
}

.soaPdf {
    vertical-align: middle;
    margin-right: 20px;
}

button.disabledForm {
    opacity: 0.7;
}

input.disabledForm,
label.disabledForm {
    background-color: #cfcbc3 !important;
}

.disabledAccordion {
    color: #473f3a;
}

.LoadingIndicator {
    z-index: 3;
}